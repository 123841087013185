/* Custom styling for the Faucet application */

:root {
  --primary-color: #6c5ce7;
  --primary-light: #a29bfe;
  --secondary-color: #00cec9;
  --accent-color: #fd79a8;
  --background-color: #f8f9fa;
  --card-background: #ffffff;
  --text-color: #2d3436;
  --text-secondary: #636e72;
  --success-color: #00b894;
  --warning-color: #fdcb6e;
  --info-color: #0984e3;
  --border-radius: 12px;
  --box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  --transition: all 0.3s ease;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--primary-light) 0%, var(--secondary-color) 100%);
  padding: 2rem 1rem;
}

.app-title {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app-subtitle {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 600px;
}

.connect-button-container {
  margin-bottom: 2rem;
}

.connect-button-container w3m-button {
  --w3m-accent-color: var(--accent-color);
  --w3m-accent-fill-color: white;
}

.card-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.custom-card {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: none;
  transition: var(--transition);
  height: 100%;
  overflow: hidden;
}

.custom-card:hover {
  transform: translateY(-5px);
}

.card-header {
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  padding: 1.25rem;
  border-bottom: none;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.card-body {
  padding: 1.5rem;
}

.card-subtitle {
  color: var(--text-secondary);
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.custom-button {
  border-radius: 8px;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  transition: var(--transition);
  border: none;
  margin-bottom: 0.75rem;
}

.custom-button-primary {
  background-color: var(--primary-color);
  color: white;
}

.custom-button-primary:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

.custom-button-warning {
  background-color: var(--warning-color);
  color: var(--text-color);
}

.custom-button-warning:hover {
  background-color: #ffeaa7;
  transform: translateY(-2px);
}

.custom-button-success {
  background-color: var(--success-color);
  color: white;
}

.custom-button-success:hover {
  background-color: #55efc4;
  transform: translateY(-2px);
}

.custom-input {
  border-radius: 8px;
  padding: 0.75rem 1rem;
  border: 1px solid #dfe6e9;
  margin-bottom: 1rem;
  transition: var(--transition);
  width: 100%;
}

.custom-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(108, 92, 231, 0.1);
  outline: none;
}

.custom-input::placeholder {
  color: #b2bec3;
}

.custom-alert {
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.custom-alert-info {
  background-color: rgba(9, 132, 227, 0.1);
  color: var(--info-color);
  border-left: 4px solid var(--info-color);
}

.balance-display {
  font-size: 1.1rem;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-title {
    font-size: 2rem;
  }
  
  .app-subtitle {
    font-size: 1rem;
  }
  
  .card-container {
    padding: 0 1rem;
  }
}

/* Animation for cards */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.fade-in-delay-1 {
  animation-delay: 0.1s;
}

.fade-in-delay-2 {
  animation-delay: 0.2s;
}
